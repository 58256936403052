<template>
  <div>
    <div class="head py-4 mb-10">
      <h2
        class="text-center top-text"
        :class="$vuetify.breakpoint.xs ? 'py-10' : 'py-16'"
      >
        The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug
        Wastes
      </h2>
    </div>
    <v-row
      class="container-top"
      :class="$vuetify.breakpoint.xs ? 'pa-4' : 'pa-16'"
    >
      <v-col cols="12" sm="7" :class="$vuetify.breakpoint.xs ? '' : 'pr-12'">
        <div>
          <img src="../../assets/images/image 11 (4).png" alt="" width="100%" />
        </div>
        <div class="date d-flex justify-space-between py-7">
          <p class="ma-0" style="color:#28A84A; font-weight:500;">
            Author: Gricd
          </p>
          <!-- <span class="d-flex">
            <img
              src="../../assets/icons/calendar_today.png"
              alt=""
              class="mr-3"
              style="    width: 20px;height: 20px;"
            />
            <p class=" font-weight-bold">Date: April 1, 2019</p>
          </span> -->
        </div>
        <div class="bottom-part">
          <h1 class="pb-8 heading">
            The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug
            Wastes
          </h1>
          <div
            class="date d-flex justify-space-between py-7"
            style="flex-flow: row wrap;"
          >
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (7).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 13px;margin-top: 0.33rem;"
              />
              <p class=" font-weight-bold">50 views</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/Vector (8).png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class=" font-weight-bold">0 comments</p>
            </span>
            <span class="d-flex">
              <img
                src="../../assets/icons/calendar_today.png"
                alt=""
                class="mr-3"
                style="width: 20px;height: 20px;"
              />
              <p class=" font-weight-bold">Date: March 07, 2019</p>
            </span>
          </div>
          <p class="pb-2">Originally from <span>tekedia</span></p>
          <p>
            About 1.4 billion tonnes of food is lost annually due to lack of
            cold chain solutions. According to FIIRO, a Nigeria-based food
            research agency, a farmer loses between 40-50 percent of farm
            produce. This leads to price hikes in cost of food items, price
            fluctuations and food scarcity. More than 85 percent of farm owners
            in Nigeria are without access to cold chain storage solutions.
            Cooling and refrigeration rely an access to a reliable and
            affordable source of either electricity or diesel fuel which are
            often lacking or virtually nonexistent in rural areas.
          </p>
          <p class="font-italic">
            A cold chain or cool chain is a temperature-controlled supply chain.
            An unbroken cold chain is an uninterrupted series of refrigerated
            production, storage and distribution activities, along with
            associated equipment and logistics, which maintain a desired
            low-temperature range.
          </p>
          <p>
            In healthcare, cold chain technology is required in the preservation
            and storage of healthcare products, medications and the
            dissemination of vaccines. Unfortunately 19.4 million infants do not
            receive routine immunization due to inadequate cold chain facility
            during transportation and storage. About 1.5 million annual deaths
            could have been prevented if there were adequate cold chain storage
            facilities.
          </p>

          <img
            src="../../assets/images/image 12.png"
            alt=""
            width="100%"
            class="py-8"
          />

          <p>
            Oghenetega Iortim, a business development expert with expertise
            spanning different industries which include power, information
            technology, oil and gas where he built solutions for Nigerian Gas
            generating revenue of over $1million per annum, and his Cofounder
            Richard Amiola, a renewable energy expert, who has embarked on
            projects which harness the potential of renewable energy such as
            regenerative braking, thermoelectric generation, piezoelectricity
            and solar energy, decided to come up with a solution to this
            problem. <br /><br />
            The solution is Gricd Frij, a cold chain box for storage and
            transportation of agricultural and healthcare products, in locations
            without power infrastructure. This device embedded with real time
            storage temperature and location monitoring technology has a
            dedicated battery which lasts up to 48 hours. <br /><br />
            Gricd Frij will provide reliable, affordable cold chain solution to
            preserve crop produce, dairy milk, meat, household food, finding
            purpose across various sectors, preventing post harvest losses for
            farmers. It will also preserve vaccines and other healthcare
            products during transportation to remote, rural areas. <br /><br />
            This innovation has been adopted by the Nigerian Institute of
            Medical Research Lagos for the transportation of laboratory samples,
            a hospital and some small businesses which rely on cold chain. It
            gives the user the ability to sustain items at regulated
            temperatures of up to 20 degrees Celsius during storage and
            transportation. It is the first of its kind in Sub-Saharan Africa
            competing with the traditional means of storage which are
            conventional refrigerators and ice packs in cooler boxes.
            <br /><br />
            The Federal Ministry Of Health, Nigerian Medical Association (the
            umbrella body of all medical practitioners), and Pharmaceutical
            Society of Nigeria need to sensitize their members to patronize this
            innovation which will go a long way in preventing some of the
            wastages faced by medical centers and pharmacies in drug and other
            sensitive items during delivery. Also, the Nigerian Agribusiness
            Support Group, an umbrella body of all organizations involved in
            agribusiness, Farmers Association, and the Federal Ministry of
            Agriculture and Rural Development with its Departments and Agencies,
            should also utilize this product to prevent produce wastages.
          </p>
        </div>
      </v-col>
      <v-col cols="12" sm="5">
        <div class="search" style="padding: 2rem 2rem;">
          <input type="text" placeholder="Search" />
          <img :src="mySVG" class="search-icon" />
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">Recent Posts</h2>
          </div>
          <div class="right-sub mb-6" v-for="(m, i) in rightSide" :key="i">
            <v-row>
              <v-col cols="5">
                <img :src="m.img" alt="" width="100%" />
              </v-col>
              <v-col cols="7 pa-4">
                <span class="d-flex py-3">
                  <img
                    src="../../assets/icons/calendar_today.png"
                    alt=""
                    class="mr-3"
                    style="    width: 20px;height: 20px;"
                  />
                  <p class="caption">
                    {{ m.date }}
                  </p>
                </span>
                <p class="subtitle">
                  <router-link :to="m.link">
                    {{ m.content }}
                  </router-link>
                </p>
              </v-col>
            </v-row>
            <hr class="mt-5" v-show="i !== 2" />
          </div>
        </div>
        <div class="right_sec">
          <div class="heading-title pl-3 pb-10 justify-start">
            <span>
              <div class="line" style="border-bottom: 3px solid #28A84A;"></div>
              <div
                class="line2"
                style="border-bottom: 3px solid #28A84A;"
              ></div>
            </span>
            <h2 :class="$vuetify.breakpoint.xs ? 'pt-4' : ''">
              Our Categories
            </h2>
          </div>
          <div
            class="right-news mb-6 d-flex"
            v-for="(m, i) in info"
            :key="i"
            style="height:5rem"
          >
            <div
              style="background: rgba(242, 245, 247, 0.6); width:85%"
              class="d-flex align-center"
            >
              <p class=" ma-0 pl-5">
                {{ m }}
              </p>
            </div>
            <div
              style="background: #28A84A; width:15%"
              class="d-flex align-center"
            >
              <p class=" ma-0 mx-auto white--text">0{{ i + 1 }}</p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div class="popular mb-10">
      <div class="section-head">
        <div class="heading-title">
          <h2 style="font-size:34px">
            More amazing articles for you
          </h2>
        </div>
      </div>
      <v-row
        class="justify-space-between"
        :style="
          $vuetify.breakpoint.xs
            ? 'padding:0 1rem; justify-content:space-evenly'
            : 'padding:0 3rem; justify-content:space-evenly'
        "
      >
        <div v-for="(m, i) in lastCard" :key="i" class="mx-auto">
          <v-hover v-slot="{hover}">
            <v-card
              class=" mb-10 card-cont"
              max-width="400"
              outlined
              :style="
                hover
                  ? 'box-shadow: 0px 20px 50px 0px rgba(84, 143, 188, 0.1);'
                  : ''
              "
            >
              <v-img :src="m.img" height="300px"> </v-img>
              <v-card-title>
                {{ m.content }}
              </v-card-title>

              <v-card-actions>
                <v-btn
                  text
                  :style="hover ? 'color:#28A84A' : ''"
                  :to="m.link"
                  style="font-size:10px"
                >
                  Read More
                </v-btn>
                <v-spacer></v-spacer>
                <p class="pr-3 date">10 March, 2021</p>
              </v-card-actions>
            </v-card>
          </v-hover>
        </div>
        <!-- <v-carousel interval="4000" hide-delimiters show-arrows-on-hover cycle>
          <v-carousel-item v-for="(color, i) in 1" :key="i">
            <v-row >
              <div v-for="(m, i) in lastCard" :key="i">
                <v-card
                  class=" mb-10 card-cont"
                  max-width="400"
                  width="400"
                  outlined
                >
                  <v-img :src="m.img" height="300px"> </v-img>
                  <v-card-title>
                    {{ m.content }}
                  </v-card-title>

                  <v-card-actions>
                    <v-btn
                      color="black"
                      style="font-weight: 300;font-size: 11px;"
                      text
                    >
                      Read More
                    </v-btn>
                    <v-spacer></v-spacer>
                    <p class="pr-3 date">10 March, 2021</p>
                  </v-card-actions>
                </v-card>
              </div>
            </v-row>
          </v-carousel-item>
        </v-carousel> -->
      </v-row>
    </div>
  </div>
</template>

<script>
import img1 from '../../assets/images/Rectangle 25.png';
import img2 from '../../assets/images/Rectangle 26.png';
import img3 from '../../assets/images/image 11 (1).png';
import image4 from '../../assets/images/image (7).png';
import image5 from '../../assets/images/image (10).png';
import image6 from '../../assets/images/image (9).png';
export default {
  data: () => ({
    info: ['News', 'Health', 'Logistics'],
    rightSide: [
      {
        img: img1,
        content: 'Curbing Losses In The Food Value Chain In Africa',
        date: '29 April, 2019',
        link: '/blog/curbing-losses-in-the-food-value-chain-in-africa',
      },
      {
        img: img2,
        content: 'Technology Firm Introduces Healthcare Product',
        date: '01 April, 2019',
        link: '/blog/technology-firm-introduces-healthcare-product',
      },
      {
        img: img3,
        content:
          'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
        date: '07 March, 2021',
        link:
          '/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
      },
    ],
    lastCard: [
      {
        img: image4,
        content: 'Curbing Losses In The Food Value Chain In Africa',
        title: 'Agriculture',
        link: '/blog/curbing-losses-in-the-food-value-chain-in-africa',
      },
      {
        img: image6,
        content:
          'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
        title: 'Logistics',
        link:
          '/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
      },
      {
        img: image5,
        content: 'Technology Firm Introduces Healthcare Product',
        title: 'Healthcare',
        link: '/blog/technology-firm-introduces-healthcare-product',
      },
    ],
    mySVG: require('../../assets/Logo/search.svg'),
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Blog',
                item: 'https://gricd.com/blog',
              },
              {
                '@type': 'ListItem',
                position: 3,
                name:
                  'The Brilliance Of Nigeria’s Gricd Frij On Eliminating Food And Drug Wastes',
                item:
                  'https://gricd.com/blog/the-brilliance-of-nigeria’s-gricd-frij-on-eliminating-food-and-drug-wastes',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.head {
  background: rgba(40, 168, 74, 1);
  color: white;
  padding: 1rem 0;
  @media (max-width: 1024px) {
    padding: 3rem 0;
  }
}
.heading {
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 140%;
  color: #212529;
}
p {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #212529;
}
.subtitle {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 134.7%;
  letter-spacing: 0.5px;
  color: #212529;
}
.search {
  padding: 1rem 2rem;
  background: rgba(242, 245, 247, 0.7);
  margin-bottom: 2rem;
  position: relative;
  p {
    background: white;
    margin: 0;
    padding: 1.5rem 1rem;
  }
}
.right-sub {
  .caption {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
.right-news {
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.5px;

    color: rgba(99, 99, 99, 0.72);
  }
}
p span {
  text-decoration: underline;
  color: #28a84a;
}
.green-cont {
  width: 30%;
  border: solid #28a84a;
  background: #28a84a;
  position: absolute;
  bottom: 0;
  padding: 0.5rem;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.green-cont h2 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
}
.card-sub {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #212529;
}
.card-cont {
  background: #ffffff;
  border-radius: 5px !important;
  width: 24rem;
  @media (max-width: 767px) {
    width: 22rem !important;
  }
  @media (max-width: 1290px) and (min-width: 770px) {
    width: 23rem;
    // min-height: 34rem;
  }
}
.card2 {
  padding: 0 6rem 4rem;
}
.section-head {
  height: 15rem;
  padding: 5rem 5rem;
}
.v-card__title {
  @media (max-width: 1290px) and (min-width: 770px) {
    font-size: 15px !important;
  }
}
button.v-btn.v-btn--text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  /* or 24px */

  display: flex;
  align-items: center;
  letter-spacing: 0.005em;

  color: #292929;
}
.date {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150.8%;
  letter-spacing: 0.005em;
  color: #748494;
  margin: 0;
  @media (min-width: 768px) {
    padding-right: 10rem;
  }
}
a {
  text-decoration: none !important;
  color: black;
}
.v-card__title {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140.7%;
  letter-spacing: 0.5px;
  color: #292929;
  word-break: inherit;
}

.date {
  p {
    font-size: 0.775rem !important;
  }
}

@media (max-width: 1290px) and (min-width: 770px) {
  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 140%;
    color: #212529;
    padding-bottom: initial !important;
  }

  .section-head {
    height: 7rem;
    padding: 0rem 5rem;
  }
  .right-news p {
    font-weight: 600;
  }

  .date {
    font-size: 12px;
  }
  button.v-btn.v-btn--text {
    font-size: 10px;
  }
  .subtitle {
    font-size: 15px;
  }
  h2 {
    font-size: 1.7rem !important;
  }
}
</style>
